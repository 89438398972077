import { template as template_374b53ca9a3b4a94b1b4ee18c2c39a0d } from "@ember/template-compiler";
const FKControlMenuContainer = template_374b53ca9a3b4a94b1b4ee18c2c39a0d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
