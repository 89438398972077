import { template as template_f7c3bab32510494aa87a09a3fa3e3b1b } from "@ember/template-compiler";
const FKLabel = template_f7c3bab32510494aa87a09a3fa3e3b1b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
