import { template as template_0248cf2722bc487ba1e0788f9ef5e82b } from "@ember/template-compiler";
const WelcomeHeader = template_0248cf2722bc487ba1e0788f9ef5e82b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
