import { template as template_107a49e0b15b4a4a809a0f8cf52b9ebd } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_107a49e0b15b4a4a809a0f8cf52b9ebd(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
